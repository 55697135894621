import { config as appConfig } from 'src/config';

import type { AvailableLocales, Path } from '../types';

export function RequireCurrentProps(...props: ('path' | 'locale')[]) {
  return function (_: any, methodName: string, descriptor: PropertyDescriptor) {
    const newDescriptor: PropertyDescriptor = {
      enumerable: false,
      configurable: true,
      [descriptor.get ? 'get' : 'value']() {
        const classInstance = this as {
          _locales: Set<AvailableLocales[number]>;
          _current: {
            path?: Path;
            locale?: AvailableLocales;
          };
        };

        if (
          props.includes('path') &&
          typeof classInstance._current.path !== 'string'
        )
          throw new Error(
            `To call ${methodName} you have to provide currentPath or set infer on true via routesManager function props.`,
          );

        if (
          props.includes('locale') &&
          (classInstance._locales.size > 1 ||
            (classInstance._locales.size === 1 &&
              !classInstance._locales.has(appConfig.i18n.default))) &&
          (typeof classInstance._current.locale !== 'string' ||
            !classInstance._locales.has(classInstance._current.locale!))
        )
          throw new Error(
            `To call ${methodName} you have to provide one of locale available at route via routesManager function props.`,
          );

        return descriptor[descriptor.get ? 'get' : 'value']?.call(this);
      },
    };

    return newDescriptor;
  };
}
