import { Pathnames } from 'next-intl/navigation';
import { pathToRegexp } from 'path-to-regexp';
import { config } from 'src/config';

import { cleanHref } from '../helpers/cleanHref';
import type {
  AvailableLocales,
  AvailableRoutes,
  Path,
  RouteConfig,
  RoutesDescriptorsCollections,
} from '../types';
import { Route } from './Route';
import { RouteDescriptor } from './RouteDescriptor';

export class RoutesCollection<
  Locales extends AvailableLocales,
  Routes extends readonly Route<Locales>[],
> {
  private readonly _routes: RoutesDescriptorsCollections<Routes>;

  private _current: {
    locale?: AvailableLocales[number];
    path?: Path;
  } = {};

  constructor(config: Required<RouteConfig<Locales>>, routes: Routes) {
    this._routes = routes.reduce((acc, route) => {
      const path = route.path as AvailableRoutes<Locales>;

      return {
        ...acc,
        [path]: new RouteDescriptor({
          path: path as any,
          config: {
            index: route.config?.index ?? config.index,
            mode: route.config?.mode ?? config.mode,
            sitemap: route.config?.sitemap ?? config.sitemap,
            locales: route.config?.locales ?? config.locales,
          },
        }),
      };
    }, {}) as RoutesDescriptorsCollections<Routes>;
  }

  get routes() {
    return this._routes;
  }

  async getAllLocalesPathnames_async(): Promise<Pathnames<AvailableLocales>> {
    return Object.values<RouteDescriptor<Locales, Routes[number]['path']>>(
      this._routes,
    ).reduce(async (promiseAcc, route) => {
      const acc = await promiseAcc;

      const availablePathnames = await route.getAllPaths_async();
      const routePathnames = this.buildAllLocalesPathnames(availablePathnames);

      return {
        ...acc,
        [route.path]: routePathnames,
      };
    }, {});
  }

  getAllLocalesPathnames_sync(): Pathnames<AvailableLocales> {
    return Object.values<RouteDescriptor<Locales, Routes[number]['path']>>(
      this._routes,
    ).reduce((acc, route) => {
      const availablePathnames = route.getAllPaths_sync();
      const routePathnames = this.buildAllLocalesPathnames(availablePathnames);

      return {
        ...acc,
        [route.path]: routePathnames,
      };
    }, {});
  }

  get currentPath(): Path | undefined {
    return this._current.path;
  }

  get currentLoclae() {
    return this._current.locale;
  }

  set currentPath(path: Path) {
    this._current.path = path;
    Object.values<RouteDescriptor<Locales, AvailableRoutes<Locales>>>(
      this._routes,
    ).forEach((route) => (route.currentPath = path));
  }

  set currentLocale(locale: AvailableLocales[number]) {
    this._current.locale = locale;
    Object.values<RouteDescriptor<Locales, AvailableRoutes<Locales>>>(
      this._routes,
    ).forEach((route) => (route.currentLocale = locale));
  }

  async findRoute_async({ href }: { href: Path }) {
    const [matchedRoute] =
      (
        await Promise.all(
          Object.values<RouteDescriptor<Locales, AvailableRoutes<Locales>>>(
            this._routes,
          ).map(async (route) => {
            const allPaths = Object.values(
              await route.getAllPaths_async(),
            ) as Path[];

            const matchedPath = allPaths.find((path) => {
              const regexp = pathToRegexp(path);
              const result = regexp.exec(cleanHref(href));

              if (result) return true;
              return false;
            });

            return matchedPath && route;
          }),
        )
      ).filter(Boolean) || [];

    return matchedRoute;
  }

  findRoute_sync({ href }: { href: Path }) {
    const [matchedRoute] =
      Object.values<RouteDescriptor<Locales, AvailableRoutes<Locales>>>(
        this._routes,
      )
        .map((route) => {
          const allPaths = Object.values(route.getAllPaths_sync()) as Path[];

          const matchedPath = allPaths.find((path) => {
            const regexp = pathToRegexp(path);
            const result = regexp.exec(cleanHref(href));

            if (result) return true;
            return false;
          });

          return matchedPath && route;
        })
        .filter(Boolean) || [];

    return matchedRoute;
  }

  private buildAllLocalesPathnames(
    availablePathnames: Record<AvailableLocales[number], Path>,
  ) {
    let routePathnames: Record<AvailableLocales[number], Path> | Path;

    if (
      Object.values(availablePathnames).every(
        (path) => path === availablePathnames[config.i18n.default],
      )
    ) {
      routePathnames = availablePathnames[config.i18n.default];
    } else {
      routePathnames = config.i18n.locales.reduce(
        (acc, locale) => ({
          ...acc,
          [locale]:
            availablePathnames[locale] ??
            availablePathnames[config.i18n.default],
        }),
        {} as Record<AvailableLocales[number], Path>,
      );
    }

    return routePathnames;
  }
}
