import { ParamValueProp } from '../types';

export function RequireParams(
  _: any,
  _2: string,
  descriptor: PropertyDescriptor,
) {
  const newDescriptor: PropertyDescriptor = {
    enumerable: false,
    configurable: true,
    get() {
      const classInstance = this as {
        _path: string;
        _params: {
          [key: string]: { isRequired: boolean; value?: ParamValueProp };
        };
      };

      const params = Object.entries(classInstance._params).filter(
        ([, { isRequired, value }]) => isRequired && !value,
      );

      if (params.length > 0)
        throw new Error(
          `You have to provide missing params: [${params
            .map(([key]) => key)
            .join(', ')}], for page ${classInstance._path}`,
        );

      return descriptor.get?.call(this);
    },
  };

  return newDescriptor;
}
