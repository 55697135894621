import { Path } from '../types';

const withoutLashSlashRegex = /(?<=.)(\/)(?!.)/;

export function comparePathnames(pathname1: Path, pathname2: Path) {
  const [standardizedPathname1, standardizedPathname2] = [
    pathname1,
    pathname2,
  ].map((item) => item.replace(withoutLashSlashRegex, '')) as Path[];

  const hasSegmentsEquality =
    standardizedPathname1.startsWith(standardizedPathname2) ||
    standardizedPathname2.startsWith(standardizedPathname1);

  const hasFullEquality = standardizedPathname1 === standardizedPathname2;

  return {
    segmentsEquality: hasSegmentsEquality,
    fullEquality: hasFullEquality,
  };
}
