import { config } from 'src/config';

import { Route } from './entities/Route';
import { RoutesCollection } from './entities/RoutesCollection';

export const protectedCollection = new RoutesCollection(
  {
    mode: 'protected',
    index: false,
    sitemap: false,
    locales: [config.i18n.default],
  },
  [
    new Route('/dashboard', { locales: ['en', 'pl'] }),
    new Route('/dashboard/files', { locales: ['en', 'pl'] }),
    new Route('/dashboard/users', { locales: ['en', 'pl'] }),
    new Route('/dashboard/groups', { locales: ['en', 'pl'] }),
    new Route('/dashboard/sites', { locales: ['en', 'pl'] }),
    new Route('/dashboard/apps', { locales: ['en', 'pl'] }),
    new Route('/dashboard/user/account'),
    new Route('/dashboard/user/profile'),
    new Route('/dashboard/applications', { locales: ['en', 'pl'] }),
    new Route('/dashboard/findings', { locales: ['en', 'pl'] }),
    new Route('/dashboard/security-tasks', { locales: ['en', 'pl'] }),
  ] as const,
);
