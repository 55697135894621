import type { AvailableLocales, AvailableRoutes, RouteConfig } from '../types';

export class Route<
  Locales extends AvailableLocales,
  RoutePath extends AvailableRoutes<Locales> | undefined = undefined,
> {
  private readonly _path: AvailableRoutes<Locales>;
  private readonly _config?: Partial<RouteConfig<AvailableLocales>>;

  constructor(path: RoutePath, config?: Partial<RouteConfig<Locales>>) {
    this._path = path!;
    this._config = config;
  }

  get path() {
    return this._path;
  }

  get config() {
    return this._config;
  }
}
